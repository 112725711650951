import roadBg from '../../img/roadBetewenSection.jpg'
import css from "./RoadBg.module.css"
function RoadBg() {

    return(
        <div className={css.bgRoad}>

        </div>
    )
}


export default RoadBg